// extracted by mini-css-extract-plugin
export var socialContainer = "v_sk";
export var instagramLink = "v_sl";
export var socialWrapperLeft = "v_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "v_sm";
export var socialContentContainerFull = "v_sn";
export var instagramContainer = "v_sp";
export var twitterContainer = "v_sq";
export var facebookContainer = "v_sr";
export var socialErrorContainer = "v_ss";
export var facebookContainerWide = "v_st";
export var twitterContainerWide = "v_sv";
export var socialParagraphCenter = "v_sw";
export var instaWrapper = "v_sx";
export var SubtitleSmall = "v_qd B_qd B_s5 B_th";
export var SubtitleNormal = "v_qf B_qf B_s5 B_tj";
export var SubtitleLarge = "v_qg B_qg B_s5 B_tk";
export var textLeft = "v_dv";
export var textCenter = "v_dw";
export var textRight = "v_dx";