// extracted by mini-css-extract-plugin
export var alignLeft = "t_qh d_bG";
export var alignCenter = "t_bP d_bD";
export var alignRight = "t_qj d_bH";
export var textAlignLeft = "t_r9";
export var textAlignCenter = "t_sb";
export var textAlignRight = "t_sc";
export var hoursInnerWrapperAlt = "t_sd d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "t_sf d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "t_sg d_dw";
export var titleMargin = "t_sh d_cw";
export var hoursInnerInnerWrapper = "t_sj d_cz";