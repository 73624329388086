// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rS d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_rT d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_rV d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_rW d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_rX d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_rY d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_rZ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_r0 B_r0";
export var heroExceptionNormal = "s_r1 B_r1";
export var heroExceptionLarge = "s_r2 B_r2";
export var Title1Small = "s_r3 B_r3 B_s5 B_s6";
export var Title1Normal = "s_r4 B_r4 B_s5 B_s7";
export var Title1Large = "s_r5 B_r5 B_s5 B_s8";
export var BodySmall = "s_r6 B_r6 B_s5 B_tp";
export var BodyNormal = "s_r7 B_r7 B_s5 B_tq";
export var BodyLarge = "s_r8 B_r8 B_s5 B_tr";